import React, { useState } from "react";
import * as Mui from "@mui/material";
import { CustomTextBox } from "components/CustomComponents/CustomTextBox";
import { CustomAutoComplete } from "components/CustomComponents/CustomAutoComplete";
import { themes } from "services/constants";
import { useForm } from "react-hook-form";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { onlyNumberRegex } from "services/constants/patterns";
import sessionHandling from "services/utils/notificationUtils";
import { showToast } from "services/utils/Status";
import { CustomButton } from "components/CustomComponents/CustomButton";
export const Apply = () => {
  const { domain, userDetails, token } = useSelector(
    (state) => state.tokenReducer
  );
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { result: users } = useFetch(
    `${domain}list/employee?user_id=${userDetails.id}`
  );
  const [descriptions, setDescriptions] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [selectedAdvanceType, setSelectedAdvanceType] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const today = new Date();

  const [startDate, setStartDate] = useState(() => {
    return today.toISOString().split("T")[0];
  });

  const updateAdvanceType = (event, data) => {
    if (data) {
      setValue("advance_type", data?.label);
      setSelectedAdvanceType(data?.label);
    }
  };

  const advanceType = [
    { id: 1, type: "Business" },
    { id: 2, type: "Personal" },
  ];

  const onSubmit = async (data) => {
    setButtonLoader(true);
    try {
      const response = await fetch(`${domain}advances/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${token}`,
        },
        body: JSON.stringify({
          user: data.user_id,
          amount: data.advance_amount,
          repayment_date: data.from_date,
          advance_type: data.advance_type,
          description: data.descriptions,
        }),
      });
      const res = await response.json();
      if (response.ok) {
        showToast("success", "Advance type created successfully");
        clearAllData();
        setButtonLoader(false);
      } else if (response.status === 409) {
        sessionHandling();
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      showToast("error", error.message);
    } finally {
      setButtonLoader(false);
    }
  };

  const clearAllData = () => {
    setDescriptions("");
    setSelectedUserName("");
    setStartDate(today);
    setSelectedAdvanceType("");
    setAdvanceAmount("");
    reset();
  };

  const updateUserName = (event, data) => {
    if (data) {
      setValue("userName", data?.label);
      setValue("user_id", data?.user_id);
      setSelectedUserName(data?.label);
    } else {
      setValue("userName", null);
      setSelectedUserName(null);
    }
  };

  const updateAdvanceAmount = (event) => {
    if (
      event.target.value.length <= 15 &&
      onlyNumberRegex.test(event.target.value)
    ) {
      setAdvanceAmount(event.target.value);
      setValue("advance_amount", event.target.value);
    }
  };

  const updateDesciptions = (event) => {
    if (event.target.value.length <= 1024) {
      setValue("descriptions", event.target.value);
      setDescriptions(event.target.value);
    }
  };

  const validateStartDate = (value) => {
    if (!value) {
      return "Date is required.";
    }
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (selectedDate < currentDate) {
      return "Date cannot be in the past.";
    }
    return true;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ padding: 10 }}>
      <Mui.Grid container>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Employee Name
          </Mui.Typography>
          <CustomAutoComplete
            dataList={users?.map((obj) => ({
              ...obj,
              label: obj.user_name,
            }))}
            selectedValue={selectedUserName}
            rules={{
              ...register("userName", {
                required: "User name is required",
              }),
            }}
            updateDataFunction={updateUserName}
            placeHolder="Please select the name"
            errorMessage={
              errors?.userName?.message ? errors?.userName?.message : ""
            }
            textBoxError={errors.userName ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Advance Type
          </Mui.Typography>
          <CustomAutoComplete
            dataList={advanceType?.map((obj) => ({
              ...obj,
              label: obj?.type,
            }))}
            rules={{
              ...register("advance_type", {
                required: "Advance type is required",
              }),
            }}
            selectedValue={selectedAdvanceType}
            updateDataFunction={updateAdvanceType}
            placeHolder="Please select the advance type"
            errorMessage={
              errors?.advance_type?.message ? errors?.advance_type?.message : ""
            }
            textBoxError={errors?.advance_type ? true : false}
          />
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Advance Amount
          </Mui.Typography>
          <CustomTextBox
            selectedValue={advanceAmount}
            rules={{
              ...register("advance_amount", {
                required: "Advance amount is required",
              }),
            }}
            updateDataFunction={updateAdvanceAmount}
            placeHolder="Enter loan amount"
            errorMessage={
              errors?.advance_amount?.message
                ? errors?.advance_amount?.message
                : ""
            }
            textBoxError={errors.advance_amount ? true : false}
          />
        </Mui.Grid>
        <Mui.Grid xs={6} sx={{ padding: 1 }}>
          <Mui.Typography
            sx={{
              fontSize: 20,
              color: themes.headLine,
              padding: 1,
            }}
          >
            Payment Date
          </Mui.Typography>
          <Mui.TextField
            fullWidth
            size="small"
            type="date"
            id="from_date"
            value={startDate}
            {...register("from_date", {
              required: "Date is required.",
              validate: validateStartDate,
            })}
            error={!!errors.from_date}
            helperText={errors.from_date && errors.from_date.message}
            onChange={(event) => {
              setStartDate(event.target.value);
            }}
          />
        </Mui.Grid>
      </Mui.Grid>

      <Mui.Grid container sx={{ padding: 1 }}>
        <Mui.Typography
          sx={{
            fontSize: 20,
            color: themes.headLine,
            padding: 1,
          }}
        >
          Description
        </Mui.Typography>
        <CustomTextBox
          type="multiline"
          selectedValue={descriptions}
          rules={{
            ...register("descriptions", {
              required: "Description is required",
            }),
          }}
          updateDataFunction={updateDesciptions}
          placeHolder="Enter descriptions"
          errorMessage={
            errors?.descriptions?.message ? errors?.descriptions?.message : ""
          }
          textBoxError={errors.descriptions ? true : false}
        />
      </Mui.Grid>
      <Mui.Grid
        container
        sx={{
          paddingTop: 2,
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          paddingRight: 1,
        }}
      >
        <CustomButton
          actionName="Apply"
          typeName="submit"
          disableAction={buttonLoader}
        />
      </Mui.Grid>
    </form>
  );
};
