import React, { useState } from "react";
import * as Mui from "@mui/material";
import { themes } from "services/constants";
import { useFetch } from "services/hooks/useFetch";
import { useSelector } from "react-redux";
import { Loader } from "components/Loader";

export const History = () => {
  const { domain, userDetails } = useSelector((state) => state.tokenReducer);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { result: advanceSalaryDetails, loading } = useFetch(
    `${domain}advances/?selected_user_id=${userDetails?.id}`
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const advanceSalaryHeader = [
    { name: "" },
    { name: "Date" },
    { name: "Advance Type" },
    { name: "Advance Amount" },
    { name: "Reason for Cash" },
    { name: "Status" },
  ];

  return (
    <React.Fragment>
      {!loading && advanceSalaryDetails?.length > 0 ? (
        <Mui.TableContainer
          sx={{
            marginTop: 2,
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "auto",
          }}
          className="w-96 border rounded"
        >
          <Mui.Table className="border">
            <Mui.TableHead
              sx={{
                backgroundColor: themes.primary,
              }}
            >
              <Mui.TableRow>
                {advanceSalaryHeader?.map((value) => (
                  <Mui.TableCell
                    sx={{ color: themes.blackColor, fontWeight: "bold" }}
                  >
                    {value?.name}
                  </Mui.TableCell>
                ))}
              </Mui.TableRow>
            </Mui.TableHead>
            <Mui.TableBody>
              {advanceSalaryDetails
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((value) => (
                  <Mui.TableRow>
                    <Mui.TableCell></Mui.TableCell>
                    <Mui.TableCell>
                      {value?.repayment_date ? value?.repayment_date : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.advance_type ? value?.advance_type : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.amount ? value?.amount : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.description ? value?.description : "N/A"}
                    </Mui.TableCell>
                    <Mui.TableCell>
                      {value?.status ? value?.status : "N/A"}
                    </Mui.TableCell>
                  </Mui.TableRow>
                ))}
            </Mui.TableBody>
          </Mui.Table>
        </Mui.TableContainer>
      ) : loading ? (
        <Loader info="Loading..." />
      ) : (
        <Mui.Alert sx={{ marginTop: 3 }} severity="info">
          No data available
        </Mui.Alert>
      )}
      {advanceSalaryDetails?.length > 10 && (
        <Mui.TablePagination
          className="custom-pagination"
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={advanceSalaryDetails?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </React.Fragment>
  );
};
