import React from "react";
import { Grid, Paper } from "@mui/material";
import { themes } from "services/constants";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useDispatch } from "react-redux";
import {
  setDepartmentId,
  setDepartmentName,
} from "services/Redux/userManagament";
export const SettingsMenu = ({ setCurrentScreen, currentScreen }) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <Grid conatiner spacing={2} sx={{ marginTop: 5 }}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Leaves"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Leaves" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Leaves");
              setCurrentScreen("Leaves");
              dispatch(setGlobalSearchValue(""));
            }}
          >
            Leaves
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Permissions"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Permissions" ? themes.formBackground : null,
            }}
            onClick={() => {
              dispatch(setDepartmentId(99999));
              dispatch(setDepartmentName("All"));
              localStorage.setItem("approveSettingScreens", "Permissions");
              setCurrentScreen("Permissions");
              dispatch(setGlobalSearchValue(""));
            }}
          >
            Permissions
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Over time"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Over time" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Over time");
              setCurrentScreen("Over time");
            }}
          >
            Over Time
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Advance Salary"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Advance Salary"
                  ? themes.formBackground
                  : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Advance Salary");
              setCurrentScreen("Advance Salary");
            }}
          >
            Advance Salary
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Loans"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Loans" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Loans");
              setCurrentScreen("Loans");
            }}
          >
            Loans
          </Paper>
        </Grid>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              padding: 2,
              fontSize: 22,
              cursor: "pointer",
              color:
                currentScreen === "Week Off"
                  ? themes.blackColor
                  : themes.blackColor,
              backgroundColor:
                currentScreen === "Week Off" ? themes.formBackground : null,
            }}
            onClick={() => {
              localStorage.setItem("approveSettingScreens", "Week Off");
              setCurrentScreen("Week Off");
            }}
          >
            Week Off
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
