import React, { useContext, useState, useEffect } from "react";
import * as Mui from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BackArrow } from "components/CustomComponents/BackArrow";
import { CustomButton } from "components/CustomComponents/CustomButton";
import AddShiftAssign from "./AddShiftAssign";
import { themes } from "services/constants";
import { Loader } from "components/Loader";
import { ShiftAssignContext } from "../ShiftManageContext/ShiftAssignContext";
import { MoreVert } from "@mui/icons-material";
import { setGlobalSearchValue } from "services/Redux/userToken";
import { useSelector } from "react-redux";
import { filterShiftAsgn } from "services/constants";
import { useDispatch } from "react-redux";
import EditShiftAssign from "./EditShiftAssign";

export const ShiftAssignments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, allSftAsgn, setAllSftAsgn, masterDataForSearch } =
    useContext(ShiftAssignContext);
  const { globalSearchValue } = useSelector((state) => state.tokenReducer);
  const [showAddShiftAsgn, setShowAddShiftAsgn] = useState(false);
  const [showEditShiftAsgn, setShowEditShiftAsgn] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [shftAsgnPro, setShftAsgnPro] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selSftAsgnData, setSelSftAsgnData] = useState("");

  useEffect(() => {
    const filterShiftAssignData = masterDataForSearch.filter((item) =>
      filterShiftAsgn.some((key) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(globalSearchValue.toString().toLowerCase())
      )
    );
    setAllSftAsgn(filterShiftAssignData);
    setPage(0);
  }, [globalSearchValue, masterDataForSearch, setAllSftAsgn]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {!showAddShiftAsgn && !showEditShiftAsgn ? (
        <>
          <Mui.Grid container spacing={1} sx={{ mt: 0 }}>
            <Mui.Grid item xs={12} sm={12} md={10} lg={10}>
              <Mui.Stack direction="row" sx={{ mt: 1 }}>
                <BackArrow actionFunction={() => history.goBack()} />
                <Mui.Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: "bold",
                    mt: 1.5,
                  }}
                >
                  Shift Assignment
                </Mui.Typography>
              </Mui.Stack>
            </Mui.Grid>
            <Mui.Grid
              xs={12}
              sm={12}
              md={2}
              lg={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                paddingRight: 2,
              }}
            >
              <CustomButton
                actionFuntion={() => {
                  setShftAsgnPro("Add");
                  setShowAddShiftAsgn(true);
                  dispatch(setGlobalSearchValue(""));
                }}
                actionName="Add Shift Assignment"
                typeName="button"
              />
            </Mui.Grid>
          </Mui.Grid>

          {isLoading ? (
            <Loader info="Loading..." />
          ) : (
            <Mui.TableContainer
              component={Mui.Paper}
              sx={{ mt: 2, width: "100%", mr: 2 }}
            >
              <Mui.Table>
                <Mui.TableHead
                  sx={{
                    backgroundColor: themes.primary,
                  }}
                >
                  <Mui.TableRow>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Shift Category
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Cycle
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Start From
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      No. of Cycle
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Total Period
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      End Date
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Status
                    </Mui.TableCell>
                    <Mui.TableCell
                      sx={{ color: themes.blackColor, fontWeight: "bold" }}
                    >
                      Action
                    </Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>

                {allSftAsgn?.length !== 0 && allSftAsgn ? (
                  <Mui.TableBody>
                    {allSftAsgn
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <Mui.TableRow
                          maxWidth="xl"
                          align="left"
                          sx={{
                            border: `1px solid ${themes.formBackground}`,
                            cursor: "pointer",
                          }}
                          hover={true}
                        >
                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.shift_category_name
                                ? item?.shift_category_name
                                : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.shift_cycle ? item?.shift_cycle : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.start_date ? item?.start_date : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>

                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.no_of_cycle ? item?.no_of_cycle : 0}
                            </Mui.Typography>
                          </Mui.TableCell>

                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.total_period ? item?.total_period : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>

                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.end_date ? item?.end_date : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>

                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.Typography
                              sx={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.status ? item?.status : "N/A"}
                            </Mui.Typography>
                          </Mui.TableCell>
                          <Mui.TableCell
                            sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              maxWidth: 100,
                            }}
                          >
                            <Mui.IconButton
                              onClick={(e) => {
                                setAnchorEl(e.currentTarget);
                                setSelSftAsgnData(item);
                              }}
                              disabled={
                                item?.status === "Completed" ? true : false
                              }
                            >
                              <MoreVert />
                            </Mui.IconButton>
                            <Mui.Menu
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleMenuClose}
                              elevation={1}
                              transformOrigin={{
                                horizontal: "right",
                                vertical: "top",
                              }}
                              anchorOrigin={{
                                horizontal: "right",
                                vertical: "bottom",
                              }}
                            >
                              <Mui.MenuItem
                                onClick={() => {
                                  setShftAsgnPro("View");
                                  setShowEditShiftAsgn(true);
                                  handleMenuClose();
                                }}
                              >
                                View
                              </Mui.MenuItem>
                            </Mui.Menu>
                          </Mui.TableCell>
                        </Mui.TableRow>
                      ))}
                  </Mui.TableBody>
                ) : (
                  <Mui.TableRow>
                    <Mui.TableCell colSpan={6}>
                      <Mui.Stack sx={{ padding: 0.5 }} spacing={2} fullWidth>
                        <Mui.Alert severity="info">No data available</Mui.Alert>
                      </Mui.Stack>
                    </Mui.TableCell>
                  </Mui.TableRow>
                )}
              </Mui.Table>
            </Mui.TableContainer>
          )}
          {allSftAsgn.length > 10 ? (
            <Mui.TablePagination
              className="custom-pagination"
              component="div"
              rowsPerPageOptions={[10, 20, 50, 100]}
              count={allSftAsgn.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      ) : null}
      {showEditShiftAsgn ? (
        <EditShiftAssign
          updateShowAddShiftAsgn={setShowEditShiftAsgn}
          shftAsgnPro={shftAsgnPro}
          selSftAsgnData={selSftAsgnData}
        />
      ) : null}
      {showAddShiftAsgn ? (
        <AddShiftAssign
          updateShowAddShiftAsgn={setShowAddShiftAsgn}
          shftAsgnPro={shftAsgnPro}
        />
      ) : null}
    </React.Fragment>
  );
};
